.id{
  color: #f1f1f1;
  font-weight: 800;
  // border:2px solid rgb(142, 72, 142);
  font-size: larger;
  // border-radius: 5px;
  // padding:4px;
}
.crds_text{
  text-align: justify;
  text-justify: inter-word;
}
ol ul{
  padding:0;
}
.main {
    margin: 0 auto;
  }
  
  .crdss {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding-top:10%;
  }
  
  .crdss_item {
    display: flex;
    padding: 1rem;
    width: 70%;
    margin: 0 auto;
    border:2px solid rgb(191, 101, 191);
    position:relative;
    margin-bottom:3rem;
  }
  
  .crds_image {
    max-height: 250px;
    margin-top: -24px;
    img {
      width: 100%;
      max-width: 800px;
      height: 100%;
      object-fit: cover;
    }
    
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      position: relative;
      top: -100px;
      background: linear-gradient(0deg, #091014 10%, rgba(0, 0, 0, 0) 100%);
    }
  }
  
  .crds {
    border-radius: 0.25rem;
    // box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position:relative;
    margin-bottom: 1.5rem;
  }
  
  .crds_content {
    padding: 1rem;
    z-index: 1;
    display: block;
  }
  
  .crds_title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    margin-bottom: 2rem;
    text-shadow: black 1px 0 15px;
    color: rgb(191, 101, 191);
    position:relative;
  }
  
  .crds_text {
    color: #DDDACB;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    p {
      // max-width: 700px;
      font-size: 1.5rem;
    }
  }
  
  .crds_price {
    position: relative;
    top: 10px;
    right: 16px;
    text-align: right;
    color: white;
    font-family: "Raleway", sans-serif;
    height: 24px;
    font-size: 36px;
    font-weight: 400;
    text-shadow: black 3px 0px 6px;
  }
  
  #item_fig .crds:after {
    content: "Seasonal";
    font-family: "Raleway", sans-serif;
    color: rgba(234, 225, 175, 0.8);
    font-weight: 600;
 font-style: italic;
    text-transform: uppercase;
    display: block;
    width: 10ch;
    height: 1.5em;
    padding: 0.2em 0.4em;
    position: relative;
    bottom: calc(100% - 2.5em);
    left: 1em;
  }

  @media screen and (max-width: 850px) {
.crdss{
padding-top: 35%;
// margin-left: 5%;
// margin-right: 5%;
}
.crds_title{
  font-size: 2rem;
}
.crdss_item {
width:90%;
}
// .crds_content{
//   padding:0;
// }
.crds_text {
  color: #DDDACB;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  p {
    // max-width: 700px;
    font-size: 1.3rem;
  }
}

.crds_content {
  padding: .5rem;
  z-index: 1;
  display: block;
}
  }
