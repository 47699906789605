.team-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
    background-color: rgb(50 50 50 / .5);
    border-radius: 15px;
    /* opacity: .5; */
  }
  
  .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    width: 250px;
    margin: 20px;
    text-align: center;
    overflow: hidden;
    align-items: center;
    /* opacity: 1.5; */
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-bottom: 2px solid #ddd;
  }
  
  .card-content {
    padding: 15px;
    
  }
  
  .card-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .card-designation {
    font-size: 16px;
    color: #666;
    margin-top: 5px;
    
  }
  