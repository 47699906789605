.spnsors {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 7% 10%;
    flex-direction: column;
    text-align: center;
}

.spoName {
    margin-top: 15px;
    color: white;
    font-size: 1.2rem;
}

.company-btns {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to the next line if there are too many items */
    justify-content: center; /* Align the items to the center */
    gap: 50px; /* Adjust gap between images for uniformity */
}

.company-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: rgb(29, 29, 29);
    width: 250px; /* Adjusted width */
    height: 200px; /* Adjusted height */
    border-radius: 20px;
    outline: 1px solid white;
    box-shadow: 0 0 10px #461959;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.company-btn img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit:fill; /* Ensures the image covers the entire container */
}

.company-btn:hover {
    box-shadow: 0 0 30px #461959;
}

.spnsors h1 {
    font-size: 2.5rem;
    color: rgb(220, 233, 197);
    margin-bottom: 20px;
}
