@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800&display=swap');
@import url('https://fonts.cdnfonts.com/css/blanka');

.header-nav {
    height:13%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1%;
    position: fixed;
    width: 100%;
    backdrop-filter: blur(2px) saturate(180%);
    -webkit-backdrop-filter: blur(2px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.75);
    border: 1px solid rgba(255, 255, 255, 0.125);
    z-index: 9999;
    transition: 0.4s;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    will-change: contents;
}

.header-nav .branding .evt{
    display: inline-block;
    width: auto;
    transition: width 0.4s ease;
    will-change: width;
    margin-left:15px;
    height:95px;
}
.clg{
    width:80px;

}
.dept{
    width:95px;
}

.branding{
    display: flex;
    align-items: center;
    gap: 0 10px;
    margin-right: auto;
}

.header-nav .branding .brand-name{
    color: #eeeeee;
    font-size: 1.7rem;
    font-family: 'Blanka', sans-serif;
    letter-spacing: 0.3rem;
    cursor: pointer;
    transform: translateY(-5px);
    transition: filter 0.3s ease, font-size 0.4s;
}

.header-nav .branding .brand-name:hover{
    filter: drop-shadow(0 0 4px whitesmoke);
}

.header-nav .nav-buttons{
    display: flex;
    gap: 0 13px;
    margin-right: 3%;
}

.nav-btn{
    font-size: 1.2rem;
    color: #eeeeee;
    transition: all 0.3s;
    text-decoration: none;
    padding: .5rem 1rem;
    border-radius: 12px;
}

.nav-btn:hover{
    color: white;
    background-color: rgb(191, 101, 191) ;
    transform: scale(1.1);
    box-shadow: 0 0 15px #461959;
}

.hamburger{
    position: fixed;
    right: 4%;
    top: 38px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 5px;
    cursor: pointer;
    z-index: 10000;
    transition: top 0.3s;
}

.line{
    display: inline-block;
    height: 2px;
    width: 25px;
    background-color: #eeeeee;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.nav-buttons-mobile{
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(40vw);
    top: 0px;
    gap: 30px;
    height: 100vh;
    width: 60vw;
    backdrop-filter: blur(2px) saturate(180%);
    -webkit-backdrop-filter: blur(2px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.75);
    border: 1px solid rgba(255, 255, 255, 0.125);
    z-index: 9999;
    padding: 10% 5%;
    transition: transform 0.3s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.nav-buttons-mobile .nav-btn{
    width: 100%;
    text-align: center;
}

.hidden-nav{
    transform: translateX(100vw)
}

.close-1{
    transform: rotate(45deg) translateY(10px);
}

.close-2{
    opacity: 0;
    transform: translateX(-35px);
}

.close-3{
    transform: rotate(-45deg) translateY(-10px);
}

@media screen and (min-width: 350px) and (max-width: 800px){
    .header-nav .nav-buttons{
        display: none;
    }
    .header-nav .branding .evt{
        display: inline-block;
        width: 110px;
        transition: width 0.4s ease;
        will-change: width;
        margin-left:7px;
        height:auto;
    }
    .clg{
        width:65px;
    
    }
    .dept{
        width:80px
    }
    .nav-buttons-mobile{
        display: flex
    }

    h1{
        font-size: 1.7rem;
    }

    .hamburger{
        display: flex;
    }

    .hero img{
        width: 90%;
    }

    .countdown-date{
        padding: 20% 2%;
        height: unset;
        gap: 50px;
    }

    .countdown-date h1{
        font-size: 1.5rem;
    }    

    .event-cards{
        flex-direction: column;
    }

    .nav-btn:focus{
        color: white;
        background-color:rgb(191, 101, 191) ;
        transform: scale(1.1);
        box-shadow: 0 0 15px #461959;
    }

    .about-us{
        padding: 10% 8%;
        gap: 30px;
        text-align: center;
    }

    .event-card{
        height: auto;
        padding: 10% 4%;
    }
    
    .event-reg-btns{
        flex-direction: column;
        gap: 30px;
    }

    .event-info{
        top: 75%;
    }

    .event-info a{
        opacity: 100%;
    }

    .event-reg-btn:hover .event-info{
        transform: translateY(20%);
        opacity: 100%;
        border-top: 3px solid rgba(255, 255, 255, 0.125)
    }
    
    .event-reg-btn:hover .event-info a{
        opacity: 100%;
    }

    .company-btns{
        flex-direction: column;
    }

    .company-btn{
        width: 60%;
    }

    footer{
        align-items: center;
        flex-direction: column;
        padding: 7% 9%;
    }

    .social-handles{
        margin-left: 10%;
        margin-right: auto;
    }

    .schedule-event-logo{
        display: none;
    }

}
