.hero{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 99vh; */
    /* outline: 1px solid red; */
    flex-direction: column;
    /* gap: 50px; */
    padding:0;
    padding-bottom: 0%;
    width: 100%;
}
.video-container {
    display: flex;
    justify-content: center;
    margin: 70px 0;
    z-index: 10; /* Ensure it's on top of other elements */
    position: relative;
}

.glitch-image {
    width: 40%;
  }
  
  @media screen and (min-width: 350px) and (max-width: 850px) {
    .glitch-image {
      width: 90%;
    }
  }
.video-container iframe {
    width: 50%;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block; /* Ensures it's visible */
    z-index: 10; /* Make sure it's layered on top */
    position: relative;
}

.hero-cont {
    position: relative;
    z-index: 1; /* Make sure the container is layered correctly */
}

.hero {
    position: relative;
    z-index: 1;
}

.pr{
margin-bottom: 15px;
display: block;
}
.presents{
    color:white;
    font-size: xx-large;
    text-align: center;
}
.sponsors{
    /* color:rgb(191, 101, 191); */
    width:90%;
    margin-top: 15px;
    text-align: center;
    color:white;
    font-size: 2rem;
}
.sp-hwk{
    color:#82CD47;
    font-size:2rem ;
    
}

  
.sp-Hyundai{
    color:#568ace;
    font-size:2rem ;
}
.lineUp {
    animation:anim-lineUp 5s ease-out infinite;
  }
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
.hero-cont{
    padding-top:8%;
}
.hero img{
    width: 40%;
    /* z-index: -9999; */
    margin: auto;
}
.desc-cont{
        position:relative;
        text-align: center;
        margin: 2% 15%;
        color:white;
        font-size:2rem;
}
.desc-cont span{
    color:rgb(191, 101, 191);
}
.countdown-txt{
    position:relative;
    text-align: center;
    font-size: 2.5rem;
    color:white;
    margin:3%;
    font-weight: 600;
}
.about-us{

    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4% 6%;
    /* gap: 50px; */
    background:rgba(15, 15, 15, 0.7);
    color:    rgb(191, 101, 191);
    border-radius: 35px;
    margin:0 2%;
    text-align: justify;
    /* height: max-content; */
    /* outline: 1px solid red; */
}

.about-us {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 1.3rem;
    color:white;
}
.hmc .mg{
    padding-top:4%;
}
@media screen and (min-width: 350px) and (max-width: 800px){
    .countdown-txt{
        margin:10% 2%;

    }
    .presents{
        font-size: 4vw;
        /* width:90%; */
    }
    .header-nav .nav-buttons{
        display: none;
    }

    .nav-buttons-mobile{
        display: flex
    }

    h1{
        font-size: 1.7rem;
    }

    .hamburger{
        display: flex;
    }
    .hero{
        margin-top:30%;
    }
    .hero img{
        width: 90%;
    }
    .desc-cont{
        font-size: 1.7rem;
    }
    .countdown-date{
        padding: 20% 2%;
        height: unset;
        gap: 50px;
    }

    .countdown-date h1{
        font-size: 1.5rem;
    }    

    .event-cards{
        flex-direction: column;
    }



    .about-us{
        padding: 10% 8%;
        gap: 30px;
        text-align: justify;
    }

    .event-card{
        height: auto;
        padding: 10% 4%;
    }
    
    .event-reg-btns{
        flex-direction: column;
        gap: 30px;
    }

    .event-info{
        top: 75%;
    }

    .event-info a{
        opacity: 100%;
    }

    .event-reg-btn:hover .event-info{
        transform: translateY(20%);
        opacity: 100%;
        border-top: 3px solid rgba(255, 255, 255, 0.125)
    }
    
    .event-reg-btn:hover .event-info a{
        opacity: 100%;
    }

    .company-btns{
        flex-direction: column;
    }

    .company-btn{
        width: 60%;
    }

    footer{
        align-items: center;
        flex-direction: column;
        padding: 7% 9%;
    }

    .social-handles{
        margin-left: 10%;
        margin-right: auto;
    }

    .schedule-event-logo{
        display: none;
    }
    .sponsors{
        font-size:1.2rem
    }
    .sp-hwk{
        font-size: 1.2rem;
    }
    .sp-Hyundai{
        font-size: 1.2rem;
    }

    .video-container iframe {
        width: 90%; /* Full width for mobile devices */
        height: 300px; /* Adjust height to maintain aspect ratio */

    }
}
.abt-us h1{
color: rgb(191, 101, 191) ;
}