

  
  .subtitle {
    max-width: 576px;
    margin: 36px auto;
    letter-spacing: .1em;
    line-height: 1.3em;
  }
  
  .countdown-wrapper {
      width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 -8px 0 -8px;
    position:relative;
  }
  
  .time-section {

    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: rgb(191, 101, 191) !important;
  }
  
  .time {
      margin: 0;
    font-size: 4rem ;
    font-weight: 800 !important;
  }
  
  .small {
      font-size: 1.1rem ;
  }
  @media screen and (min-width: 350px) and (max-width: 800px){
    .time {
        font-size: 2.8rem;
    
    }
    .small{
        font-size: 1rem;
    }
  }