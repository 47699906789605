
@mixin tablet-and-up {
  @media screen and (min-width: 769px) { @content; }
}
@mixin mobile-and-up {
  @media screen and (min-width: 601px) { @content; }
}
@mixin tablet-and-down  {
  @media screen and (max-width: 768px) { @content; }
}
@mixin mobile-only {
  @media screen and (max-width: 600px) { @content; }
}


ul, li{
list-style: none;
padding: 0;
padding-right: inherit;

}
.mg{
  padding-top:5%;
  @include mobile-only{
padding-top:16%;  
  }
}
.con{
display: flex;
justify-content: center;
align-items: center;
padding: 0 1rem;
// background: linear-gradient(45deg, #209cff, #68e0cf);
padding: 3rem 0;
}
.cvr{
  border:3px solid rgb(191, 101, 191);
}
.wr{
// background: #eaf6ff;
padding: 2rem;
border-radius: 15px;
position:relative;

}
.wr h1{
font-size: 1.1rem;
font-family: sans-serif;
text-align: center;
color:  rgb(191, 101, 191);
};

.sessions{
margin-top: 2rem;
border-radius: 12px;
position: relative;
}
.sessions li{
padding-bottom: 1.5rem;
border-left: 1px solid #abaaed;
position: relative;
padding-left: 20px;
margin-left: 10px;
&:last-child{
  border: 0px;
  padding-bottom: 0;
}
&:before{
  content: '';
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid #4e5ed3;
  box-shadow: 3px 3px 0px #bab5f8;
  box-shadow: 3px 3px 0px #bab5f8;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: 0px;
}
}
.tm{
color:  rgb(191, 101, 191);
font-family: 'Poppins', sans-serif;
font-weight: 500;
@include mobile-and-up{
  font-size: 1.9rem;
}
@include mobile-only{

  font-size: 1.2rem;
  color: rgb(191, 101, 191);
}

}
.wr p{
color: white;
    font-family: sans-serif;
line-height: 1.5;
margin-top:0.4rem;
@include mobile-only{
  font-size: 1rem;

}
}